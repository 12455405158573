@import 'variables';
@import '~bootstrap/scss/bootstrap.scss';
@import 'normalize';
@import 'typography';
@import 'home';
@import 'components/card';
@import 'components/services';
@import 'components/forms';

// App Specific
$thumb-radius: 24px;
$thumb-height: 48px;
$thumb-width: 48px;
$thumb-color: map_get($theme-colors, 'primary');
$thumb-shadow-size: 2px;
@import 'input_range';

.graph {
  position: relative;
  border-bottom: 2px solid map_get($theme-colors, 'primary');
  margin: 35px 0;
  [class^='point-'] {
    background-color: #ccc;
    position: absolute;
    top: 0;
    width: 30px;
    height: 30px;
    z-index: 1;
    // opacity: 0.75;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    // border: 1px solid map_get($theme-colors, 'primary');
    box-shadow: 0px 0px 2px rgba(0,0,0,0.3);
    span {
      padding: 5px 10px;
      background: #ccc;
      position: absolute;
      top: -43px;
      display: none;
      border-radius: 3px;
    }
    span {
      color: white;
      background: map_get($theme-colors, 'primary');
      border: 1px solid #c2e1f5;
    }
    span:after, span:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    span:after {
      border-color: rgba(136, 183, 213, 0);
      border-top-color: map_get($theme-colors, 'primary');
      border-width: 10px;
      margin-left: -10px;
    }
    span:before {
      border-color: rgba(194, 225, 245, 0);
      border-top-color: #c2e1f5;
      border-width: 11px;
      margin-left: -11px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    cursor: default;
    &:hover {
      // z-index: 1;
      // text-indent: 0;
      span {
        display: block;
      }
    }
  }
  .point-average {
    background-color: map_get($theme-colors, 'secondary');
    width: 70px;
    height: 70px;
    z-index: 0;
    opacity: 1;
    box-shadow: none;
    span {
      top: -50px;
      font-size: 18px;
      // color: black;
      // background-color: map_get($theme-colors, 'secondary');
      // &:after {
      //   border-top-color: map_get($theme-colors, 'secondary');
      // }
      // color: map_get($theme-colors, 'secondary');
    }
  }
  .point-median {
    background-color: map_get($theme-colors, 'primary');
    width: 70px;
    height: 70px;
    z-index: 0;
    opacity: 1;
    box-shadow: none;
    span {
      top: -50px;
      font-size: 18px;
      // color: black;
      // background-color: map_get($theme-colors, 'secondary');
      // &:after {
      //   border-top-color: map_get($theme-colors, 'secondary');
      // }
      // color: map_get($theme-colors, 'secondary');
    }
  }
}

.chevron-up,
.chevron-down {
  background-position: center;
  background-repeat: no-repeat;
  text-indent: -9999px;
  min-width: 40px;
}
.chevron-up {
  background-image: url(../images/chevron-white-up.svg);
}
.chevron-down {
  background-image: url(../images/chevron-white-down.svg);
}

.hyphens {
  hyphens: auto;
}

.react-slidedown.blueocean-dropdown-slidedown {
    transition-duration: 0.3s;
    // transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
}

// input.no-spinner::-webkit-outer-spin-button,
// input.no-spinner::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }

// /* Firefox */
// input[type=number].no-spinner {
//   -moz-appearance: textfield;
// }

// body {
//   margin: 0;
//   padding: 0;
//   // background: #000;
//   overflow: hidden; }

.pyro > .before/*, .pyro > .after*/ {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  box-shadow: -120px -218.66667px blue,
    248px -16.66667px #00ff84,
    190px 16.33333px #002bff,
    -113px -308.66667px #ff009d,
    -109px -287.66667px #ffb300,
    -50px -313.66667px #ff006e,
    226px -31.66667px #ff4000,
    180px -351.66667px #ff00d0,
    -12px -338.66667px #00f6ff,
    220px -388.66667px #99ff00,
    -69px -27.66667px #ff0400,
    -111px -339.66667px #6200ff,
    155px -237.66667px #00ddff,
    -152px -380.66667px #00ffd0,
    -50px -37.66667px #00ffdd,
    -95px -175.66667px #a6ff00,
    -88px 10.33333px #0d00ff,
    112px -309.66667px #005eff,
    69px -415.66667px #ff00a6,
    168px -100.66667px #ff004c,
    -244px 24.33333px #ff6600,
    97px -325.66667px #ff0066,
    -211px -182.66667px #00ffa2,
    236px -126.66667px #b700ff,
    140px -196.66667px #9000ff,
    125px -175.66667px #00bbff,
    118px -381.66667px #ff002f,
    144px -111.66667px #ffae00,
    36px -78.66667px #f600ff,
    -63px -196.66667px #c800ff,
    -218px -227.66667px #d4ff00,
    -134px -377.66667px #ea00ff,
    -36px -412.66667px #ff00d4,
    209px -106.66667px #00fff2,
    91px -278.66667px #000dff,
    -22px -191.66667px #9dff00,
    139px -392.66667px #a6ff00,
    56px -2.66667px #0099ff,
    -156px -276.66667px #ea00ff,
    -163px -233.66667px #00fffb,
    -238px -346.66667px #00ff73,
    62px -363.66667px #0088ff,
    244px -170.66667px #0062ff,
    224px -142.66667px #b300ff,
    141px -208.66667px #9000ff,
    211px -285.66667px #ff6600,
    181px -128.66667px #1e00ff,
    90px -123.66667px #c800ff,
    189px 70.33333px #00ffc8,
    -18px -383.66667px #00ff33,
    100px -6.66667px #ff008c;
  animation: 1s bang ease-out 1 forwards,
    1s gravity ease-in 1 forwards;
    // 5s position linear infinite backwards;
    margin-left: 10%;
}

// .pyro > .after {
//   animation-duration: 1.25s, 1.25s, 6.25s; }

@keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white; } }

@keyframes gravity {
  to {
    transform: translateY(200px);
    opacity: 0;
  }
}

// @keyframes position {
//   0%, 19.9% {
//     margin-top: 10%;
//     margin-left: 40%;
//   }

//   20%, 39.9% {
//     margin-top: 40%;
//     margin-left: 30%;
//   }

//   40%, 59.9% {
//     margin-top: 20%;
//     margin-left: 70%;
//   }

//   60%, 79.9% {
//     margin-top: 30%;
//     margin-left: 20%;
//   }

//   80%, 99.9% {
//     margin-top: 30%;
//     margin-left: 80%;
//   }
// }


