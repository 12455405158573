// *[id]:before {
//   display: block;
//   content: " ";
//   margin-top: -75px;
//   height: 75px;
//   visibility: hidden;
// }

header {
  position: sticky;
  top: 0;
  z-index: 1;
  // background: #709faf;
  .logo {
    img {
      max-width: 100px;
      @media screen and (min-width: map-get($grid-breakpoints, sm)) {
        max-width: 150px;
      }
    }
  }
  nav {
    @media screen and (max-width: 767px) {
      max-width: 320px;
      font-size: 0.8rem;
    }
  }
}

section {
  h1 {
    font-weight: bold;
    @extend .mb-3;
    @extend .mb-md-3;
    // @media screen and (min-width: map-get($grid-breakpoints, md)) {
      // margin-bottom: $spacer * 4;
    // }
  }
  h2 {
    @extend .mb-3;
    @extend .mb-md-4;
  }
}

.hero {
  min-height: (9/16)*100vw;
  background-size: cover;
  .containr {
    max-width: 500px;
  }
  h1 {
    font-size: 3rem;
    @media screen and (min-width: map-get($grid-breakpoints, md)) {
      font-size: 4rem;
    }
    font-weight: bold;
    color: white;
    em {
      // color: #F5A623; // yellow
      font-style: normal;
      @extend .text-secondary;
    }
  }
  .btn {
    color: #1F5762;
    font-weight: bold;
  }
}

.misconceptions {
  color: white;
  // background: map-get($theme-colors, primary);
  background: #006b8a;
  // background-image: url(../images/patterns/pinstripe.png);
  // background-image: url(../images/patterns/positive.png);
  // background-image: url(../images/patterns/ps_neutral.png);
  // background-image: url(../images/patterns/wavecut.png);
  // background-image: url(../images/patterns/wavecut_@2X.png);
  // background-blend-mode: multiply;
  background-image: url(../images/patterns/wavecut_blue.png);
  h1 {
    color: white;
    @extend .mb-3;
    @extend .mb-md-5;
  }
  h2 {
    @extend .h3;
    // font-size: 26px;
    color: white;
    @extend .mb-1;
    @extend .mb-md-2;
  }
  .btn-primary {
    // background: #1F5762;
  }
}

.services {
  background: #eee;
  img {
    float: left;
    width: 40px;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 0 !important;
    @media screen and (min-width: map-get($grid-breakpoints, sm)) {
      margin-top: 0px;
      width: 33px;
    }
    @media screen and (min-width: map-get($grid-breakpoints, md)) {
      float: none;
      width: 20%;
      margin-bottom: 0.75rem !important;
    }
    @media screen and (min-width: map-get($grid-breakpoints, lg)) {
      width: 33%;
      margin-bottom: 1rem !important;
    }
  }
  .btn {
    cursor: pointer;
  }
  ul {
    padding-left: 1.25rem;
    li {
      list-style: initial;
    }
  }
}

.who .person h1 {
  @extend .h3;
}
.who img {
  max-width: 150px;
  height: auto;
}

.contact {
  background: #eee;
}

footer {
  // color: white;
  // background: black;
}