@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);

body {
  // background: #fff;
  font-family: 'Open Sans', sans-serif;
  // overflow-x: hidden;
  // color: #777;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.04em;
}

h1, h2, h3, h4, h5, h6 {
  color: #333;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  letter-spacing: -1px;
  margin: 0;
  strong {
    letter-spacing: -0.2px;
  }
}
