// /* Theme Colors */
// $off-white: #fdfbf7;
// $light-blue: #a1dced;
// $light-orange: #f7c1ad;
// $orange: #ea5329;
// $olive: rgb(162, 177, 172);
// $black: #272525;
// $white: #ffffff;
// $light-grey: #f1f0ed;
// // linear-gradient(rgba(234, 83, 41,0.5), rgba(161,220,237,0.5))

// /* Fonts: */
// $heading-font: "bookmania";
// $body-font: "brother-1816";

// /* Media query break points: */
// $mobile-breakpoint: 600px;
// $tablet-breakpoint: 1000px;

$theme-colors: (
  // "primary": #709faf,
  // "primary": #2497A2,
  "primary": #0087AE,
  "secondary": #F5A623,
);
$enable-responsive-font-sizes: true;